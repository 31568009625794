import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as Api from '../apis';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import MainLayout from '../components/MainLayout';
import ProductCard from '../components/ProductCard';
// import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import withAuth from '../hooks/withAuth';
import SearchInput from '../components/SearchInput';
import { getUrlParams } from '../helpers/location';

const useStyles = makeStyles(theme => ({
  coverImg: {
    width: '100%',
  },
  header: {
    padding: 0,
    margin: '5px 0px',
  },
  red: {
    color: 'red',
  },
  divider: {
    margin: 15,
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  category: {
    margin: '5px 10px',
    color: '#3783e4',
  },
}));

const LocationListPage = ({ location }) => {
  const classes = useStyles();
  const [locations, setLocations] = useState([]);
  const [category, setCategory] = useState(null);
  const query = getUrlParams(location.search);
  const category_id = query.category_id || '';
  const { user_question_id = '' } = query;

  useEffect(() => {
    Api.postCountData('hospital', user_question_id);
    if (category_id) {
      Api.getCategoryById(category_id).then(data => {
        setCategory(data);
      });
    }
    fetchLocations();
  }, []);

  function handleClick(url, productId) {
    Api.updateCountLocation(productId).then(() => {
      window.location = url;
    });
  }

  function fetchLocations(text) {
    Api.getLocations(category_id, text).then(result => {
      setLocations(result.results);
    });
  }

  function onSearch(text) {
    fetchLocations(text);
  }

  return (
    <MainLayout>
      <div style={{ padding: 10, maxWidth: 500 }}>
        <SearchInput
          onSearch={onSearch}
          placeholder='ชื่อ รพ, ชื่อศูนย์, จังหวัด, บางเขน, ถนน.......'
        />
      </div>
      <Divider className={classes.divider} />
      <h3 className={classes.category}>{category && category.name}</h3>
      <Grid container>
        {locations.map(location => {
          return (
            <Grid item xs={12} sm={4} md={3} lg={3} key={location.id}>
              <Box
                p={1}
                key={location.id}
                display='flex'
                justifyContent='center'
              >
                <ProductCard
                  {...location}
                  onClick={() => handleClick(location.url, location.id)}
                />
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </MainLayout>
  );
};

export default withAuth(LocationListPage);
